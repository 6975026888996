import React from 'react'
import Markdown from 'react-markdown'
import styled from 'styled-components'

const NewsHolder = styled.div`
  width: 300px;
  height: 500px;
  display: flex;
  align-items: start;
  border-radius: 5px;
  background-color: #222;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
`
const NewsBox = styled.div`
  margin-top: 10px;
  width: 98%;
  height: auto;
  background-color: #111;
  color: aliceblue;
  border-radius: 10px;
  padding: 10px;
`
const HeaderImg = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`
const StarterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-direction: column;
`
const AuthorDateHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`
const AuthorDateText = styled.p`
  font-size: 12px;
  border-radius: 5px;
  background-color: #222;
  padding: 5px;
  border: 1px solid #333;
  margin: 6px;
  color: #ccc;
`
const TitleText = styled.h3`
  font-size: 1.2rem;
`

const StyledHtml = styled('div')`
  color: aliceblue;
  width: 100%;
  h1 {
    color: aliceblue;
    font-size: 20px;
    padding: 5px;
    border-bottom: 1px solid white;
  }
  h2 {
    font-size: 16px;
  }
  p {
    color: aliceblue;
    font-size: 16px;
  }
  a {
    color: #4d9fdc;
  }
  img {
    max-width: 100%;
  }

  :hover {
    a {
      color: #4a5ddc;
    }
  }
`
export default function News({ news }) {
  const newsArray = Array.isArray(news) ? news : [news]
  return (
    <NewsHolder>
      {newsArray.map(n => (
        <NewsBox key={n.id}>
          <HeaderImg
            alt={`Header image for the news ${n.header_img.name}`}
            src={n.header_img.url}
          />
          <StarterContent>
            <AuthorDateHolder>
              <AuthorDateText>Date: {n.upload_date}</AuthorDateText>
            </AuthorDateHolder>

            <TitleText>{n.title}</TitleText>
            <StyledHtml>
              <Markdown>{n.main_content}</Markdown>
            </StyledHtml>
          </StarterContent>
        </NewsBox>
      ))}
    </NewsHolder>
  )
}
