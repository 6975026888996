/* eslint-disable indent */
import { minWidthQueries } from '@operations/utils/useMediaQuery'
import React, { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

const topBubbles = keyframes`
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%,
      70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%,
      90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%,
      90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
`

const bottomBubbles = keyframes`
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
`

const StyledButton = styled.button`
  padding: 0.7rem 2rem;
  background-color: ${({ theme, active }) => (active ? theme.colors.darkTeal : theme.colors.teal)};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 3rem;
  font-size: ${({ theme }) => theme.font.fontSize.medium};
  font-weight: 600;
  margin-bottom: 1rem;
  transition: all 0.4s ease;
  cursor: ${({ theme }) => theme.cursor.pointer};
  appearance: none;
  border: none;
  position: relative;
  transition: transform ease-in 0.1s, background-color ease-in 0.15s;
  &:active {
    transform: scale(0.9);
    background-color: ${({ theme }) => theme.colors.darkTeal};
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }

  &::last-child {
    margin-bottom: 0;
  }
  @media ${minWidthQueries.md} {
    margin-right: 1rem;
    &::last-child {
      margin-right: 0;
    }
  }
  z-index: 2;

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
    width: 60%;
    height: 70%;
    z-index: 1;
    transition: all ease-in-out 15s;
    background-repeat: no-repeat;
  }

  &:before {
    display: ${({ animate }) => (animate ? 'block' : 'none')};
    animation: ${({ animate }) =>
      animate &&
      css`
        ${topBubbles} ease-in-out 1s forwards
      `};
    top: -40%;
    background-image: ${({
      theme,
    }) => `radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, transparent 20%, ${theme.colors.teal} 20%, transparent 30%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, ${theme.colors.teal} 15%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%)`};
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  }

  &:after {
    display: ${({ animate }) => (animate ? 'block' : 'none')};
    animation: ${({ animate }) =>
      animate &&
      css`
        ${bottomBubbles} ease-in-out 1s forwards
      `};
    bottom: -40%;
    left: 50%;
    transform: translateX(-50%);
    background-image: ${({
      theme,
    }) => `radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, transparent 10%, ${theme.colors.teal} 15%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%),
      radial-gradient(circle, ${theme.colors.teal} 20%, transparent 20%)`};
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  }
`

const BubblyButton = ({ active, onClick, children, disabled, className }) => {
  const [animate, setAnimate] = useState(false)

  const animateButton = () => {
    if (!animate) {
      setAnimate(true)
      setTimeout(() => {
        setAnimate(false)
      }, 1500)
    }
  }
  const handleOnClick = () => {
    animateButton()
    onClick()
  }

  return (
    <StyledButton
      active={active}
      animate={animate}
      className={className}
      disabled={disabled}
      type="button"
      onClick={handleOnClick}
    >
      {children}
    </StyledButton>
  )
}

export default BubblyButton
