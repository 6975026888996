import { minWidthQueries } from '@operations/utils/useMediaQuery'
import Link from 'next/link'
import React, { useRef } from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  ${({ margin }) => margin && `margin: ${margin}`};
  height: ${({ height }) => height || '70px'};
  transition: all 0.2s;
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
    background: rgba(0, 0, 0, 0.5);
  }
  img {
    width: auto;
    max-height: 100%;
  }
  @media ${minWidthQueries.md} {
    height: ${({ height }) => height || '100px'};
  }
`

const ImageButton = ({ height, margin, href, link, image, alt, target }) => {
  const imageElem = <img alt={alt} src={image} />
  if (href) {
    return (
      <StyledLink height={height} href={href} margin={margin} target={target}>
        {href && imageElem}
      </StyledLink>
    )
  }
  return (
    <StyledLink height={height} margin={margin} target={target}>
      {link && <Link href={link}>{imageElem}</Link>}
    </StyledLink>
  )
}

export default ImageButton
