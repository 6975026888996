import { maxWidthQueries, minWidthQueries } from '@operations/utils/useMediaQuery'
import React from 'react'
import styled from 'styled-components'

const InfoContentDiv = styled.div`
  padding: 1.5rem;
  font-size: 1.5rem;
  background: rgba(17, 17, 17, 0.5);
  color: white;
  text-align: center;
  height: 100%;
  line-height: 1.2em;
  p {
    margin: 0;
  }
  @media ${minWidthQueries.md} {
    font-size: 1.725rem;
    padding: 2rem 12rem;
    clip-path: polygon(150px 0%, calc(100% - 150px) 0%, 100% 100%, 0% 100%);
  }
  @media ${maxWidthQueries.sm} {
    font-size: 1rem;
  }
`

const InfoContent = ({ children }) => {
  return <InfoContentDiv>{children}</InfoContentDiv>
}
export default InfoContent
