import InfoSection from '@components/infoSection/InfoSection'
import LogosSection from '@components/logosSection/LogosSection'
import React from 'react'

const getContentComponent = item => {
  if (item.__component === 'page-components.game-info') {
    return (
      <InfoSection
        key={`${item.__component}_${item.id}`}
        badges={item.badges}
        description={item.description}
      />
    )
  }
  if (item.__component === 'page-components.logos') {
    return (
      <LogosSection
        key={`${item.__component}_${item.id}`}
        logos={item.logosList}
        title={item.title}
      />
    )
  }
  if (item.__component === 'page-components.video') {
    return null
  }
  if (item.__component === 'page-components.text') {
    return null
  }
  if (item.__component === 'page-components.image-text') {
    return null
  }
  if (item.__component === 'page-components.divider') {
    return null
  }
  return null
}

export default getContentComponent
