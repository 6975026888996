import { minWidthQueries } from '@operations/utils/useMediaQuery'
import React from 'react'
import styled, { css } from 'styled-components'

const InfoTitleWrap = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.darkGold};
  padding: 1.2rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${minWidthQueries.md} {
    padding: 1.2rem 1rem;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 180%;
    @media ${minWidthQueries.md} {
      height: 200%;
    }
    background-color: ${({ theme }) => theme.colors.darkerGold};
    clip-path: polygon(
      0% 0%,
      calc(100% - 10px) 0%,
      0% calc(30% + 10px),
      0% calc(30%),
      0% calc(30% + 25px),
      100% 5px,
      100% 100%,
      0% calc(100% - 40px)
    );
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 180%;
    @media ${minWidthQueries.md} {
      height: 200%;
    }
    background-color: ${({ theme }) => theme.colors.darkerGold};
    clip-path: polygon(
      0% 0%,
      calc(100% - 10px) 0%,
      0% calc(30% + 10px),
      0% calc(30%),
      0% calc(30% + 25px),
      100% 5px,
      100% 100%,
      0% calc(100% - 40px)
    );
    transform: scaleX(-1);
  }
  svg {
    max-width: 100%;
    height: auto;
  }
`
const InfoTitle = styled.h2`
  font-family: ${({ theme }) => theme.font.fontFamily.skyclimbers};
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  text-align: center;
  position: relative;
  z-index: 1;
  letter-spacing: 0.5rem;
  font-size: 1.5rem;

  @media ${minWidthQueries.md} {
    font-size: 250%;
    letter-spacing: 2rem;
    text-indent: 0.25rem;
  }
  ${({ centerTick }) =>
    centerTick &&
    css`
      &:after {
        @media ${minWidthQueries.md} {
          content: '';
          position: absolute;
          top: -1.2rem;
          width: 100px;
          height: 120px;
          clip-path: polygon(50% 100%, 0% 50%, 30% 0%, 70% 0%, 100% 50%);
          background-color: ${({ theme }) => theme.colors.darkerGold};
          transform: translateX(-50%);
          z-index: -1;
          left: 50%;
        }
      }
    `}
`

const GoldFullWidthTitle = ({ title, centerTick = false }) => {
  return (
    <InfoTitleWrap>
      <InfoTitle centerTick={centerTick}>{title}</InfoTitle>
    </InfoTitleWrap>
  )
}

export default GoldFullWidthTitle
