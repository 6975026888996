import React, { useRef } from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(369deg);
  }
`
const bgChange = keyframes`
  0%{
    background-color: rgba(0,0,0,1);
  }
  100%{
    background-color: rgba(45,45,45,1);
  }
`

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black no-repeat center center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 1;
  transition: opacity 1s;
  animation: ${bgChange} 5s ease-in-out infinite alternate;
  cursor: ${({ theme }) => theme.cursor.default};
`
const BackgroundLayer2 = styled.div`
  background: url(/images/intro/Pulse_Layer.png) no-repeat center center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`
const BackgroundOverlay = styled.div`
  height: 100%;
  width: 100%;
  z-index: 1001;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/images/intro/Background_top_layer.png) no-repeat center center;
  background-size: cover;
  top: 0;
  left: 0;
  position: fixed;
`

const OuterRing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  animation: ${rotate} 5s ease-in-out infinite alternate;
  animation-delay: 1s;
  img {
    transition: all 2s;
    position: absolute;
  }
`
const InnerRing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 11;
  animation: ${rotate} 5s ease-in infinite alternate;
  animation-delay: 1s;
  img {
    transition: all 2s;
    position: absolute;
  }
`
const CenterButton = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Bloom = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 9;
  opacity: 0;
`
const Keys = styled.button`
  display: flex;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 13;
  justify-content: center;
  border: none;
  background: none;
  outline: none;
`

const HoverBloom = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 80;
  &:active,
  &:focus,
  &:hover {
    border: none;
    background: none;
    outline: none;
  }
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
    & ~ ${Bloom} {
      opacity: 1;
    }
  }
`

const LeftKey = styled.div`
  position: absolute;
  transition: all 2s;
  z-index: 14;
`
const RightKey = styled.div`
  transition: all 2s;
  position: absolute;
  z-index: 14;
`

const Intro = ({ onClick }) => {
  const LeftKeyRef = useRef(null)
  const RightKeyRef = useRef(null)
  const InnerRingRef = useRef(null)
  const OuterRingRef = useRef(null)
  const BloomRef = useRef(null)
  const WrapperRef = useRef(null)

  window.onload = () => {
    if (
      localStorage.getItem('autoplay') === null ||
      localStorage.getItem('autoplay') === undefined
    ) {
      localStorage.setItem('autoplay', 'true')
    }
  }

  const closeIntro = () => {
    LeftKeyRef.current.style.transform = 'translate(-100%)'
    RightKeyRef.current.style.transform = 'translate(100%)'

    InnerRingRef.current.style.transform = 'scale(2)'
    OuterRingRef.current.style.transform = 'scale(2)'

    BloomRef.current.style.transform = 'scale(1)'
    WrapperRef.current.style.opacity = 0

    const gateSound = new Audio('/images/intro/Unlock_SFX.mp3')
    gateSound.volume = 0.05
    gateSound.play()

    setTimeout(() => {
      onClick()
    }, 2000)
  }

  const TextWrapper = styled.div`
    display: flex;
    font-size: 3em;
    color: ${({ theme }) => theme.colors.white};
    justify-content: center;
    align-items: center;
    z-index: 999;
    position: fixed;
    top: 10%;
    letter-spacing: 5px;
    animation: anim 4s linear;
    @keyframes anim {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `
  const TapToEnter = styled.div`
    transform: translate(0, -15px);
    animation: anim1 3s ease-in-out infinite;
    @keyframes anim1 {
      25% {
        transform: translate(0, 15px);
      }
    }
  `

  return (
    <Wrapper ref={WrapperRef}>
      <BackgroundLayer2>
        <BackgroundOverlay>
          <OuterRing>
            <img ref={OuterRingRef} alt="intro" src="/images/intro/ring_outer_square.png" />
          </OuterRing>

          <InnerRing>
            <img ref={InnerRingRef} alt="intro" src="/images/intro/ring_inner_square.png" />
          </InnerRing>

          <CenterButton>
            <img alt="intro" src="/images/intro/Lock_Center.png" />
          </CenterButton>

          <HoverBloom type="button" onClick={() => closeIntro()}>
            <TextWrapper>
              <TapToEnter>Tap to enter</TapToEnter>
            </TextWrapper>
            <img alt="intro" src="/images/intro/top.png" />
          </HoverBloom>

          <Keys>
            <LeftKey ref={LeftKeyRef}>
              <img alt="intro" src="/images/intro/lock_left_square.png" />
            </LeftKey>
            <RightKey ref={RightKeyRef}>
              <img alt="intro" src="/images/intro/lock_right_square.png" />
            </RightKey>
          </Keys>

          <Bloom ref={BloomRef}>
            <img alt="intro" src="/images/intro/glow.png" />
          </Bloom>
        </BackgroundOverlay>
      </BackgroundLayer2>
    </Wrapper>
  )
}

export default Intro
