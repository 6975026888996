import BubblyButton from '@components/button/BubblyButton'
import Container from '@components/layout/Container'
import Slider, { EmblaSlide } from '@components/slider/Slider'
import GoldFullWidthTitle from '@components/title/GoldFullWidthTitle'
import useMediaQuery, { maxWidthQueries, minWidthQueries } from '@operations/utils/useMediaQuery'
import React, { useRef, useState } from 'react'
import { useInViewport } from 'react-in-viewport'
import ProgressiveImage from 'react-progressive-image'
import styled from 'styled-components'

import WhiteTextLogo from '../svg/WhiteTextLogo'
import InfoContent from './InfoContent'

const StyledInfoSection = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-image: ${({ theme }) => `url(${theme.images.whitePattern})`};
  background-size: 30%;
  overflow: hidden;
`
const InfoContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  @media ${minWidthQueries.md} {
    transform: translateY(${({ animate }) => (animate ? '0' : '300px')});
    transition: transform 1.5s;
  }
`
const InfoImage = styled.img`
  width: 100%;
  @media ${minWidthQueries.md} {
    width: auto;
    height: 400px;
    max-height: 35vh;
    margin-bottom: 2rem;
  }
`
const InfoSlideImage = styled.img`
  width: 100%;
  @media ${minWidthQueries.md} {
    height: 400px;
    width: auto;
    max-height: 35vh;
  }
`
const StyledBubblyButton = styled(BubblyButton)`
  @media ${maxWidthQueries.sm} {
    margin-bottom: -23px;
  }
`

const InfoSection = ({ badges }) => {
  const isMobile = useMediaQuery(maxWidthQueries.sm)
  const animRef = useRef()
  const { enterCount } = useInViewport(animRef)
  const [activeBadge, setActiveBadge] = useState(badges?.[0])

  if (!badges) return null
  const MediaComponent = ({ badge }) => {
    const isSlider = badge?.images?.length > 1
    const imageSize = isMobile ? 'small' : 'medium'
    if (isSlider) {
      return (
        <Slider
          height={isMobile ? '190px' : '400px'}
          margin={isMobile ? '0' : '0 0 2rem 0'}
          slides={badge?.images?.map(image => {
            return (
              <ProgressiveImage
                key={image.url}
                placeholder={image.formats.thumbnail.url}
                src={image.formats[imageSize].url}
                style={{ width: '100%' }}
              >
                {(src, loading) => (
                  <EmblaSlide
                    style={{
                      filter: loading ? 'blur(2px)' : '',
                    }}
                  >
                    <InfoSlideImage alt={image.alternativeText} blur={loading} src={src} />
                  </EmblaSlide>
                )}
              </ProgressiveImage>
            )
          })}
        />
      )
    }
    return <InfoImage alt={badge.description} src={badge.images[0].formats[imageSize].url} />
  }

  return (
    <StyledInfoSection>
      <GoldFullWidthTitle centerTick title={isMobile ? 'Skyclimbers' : <WhiteTextLogo />} />
      <Container
        align="center"
        direction={isMobile ? 'column' : null}
        justify="center"
        maxWidth="740px"
        padding={isMobile ? '3rem 1rem 1rem' : '4rem 3rem 1rem'}
        wrap="wrap"
      >
        {badges?.map(item => (
          <React.Fragment key={`badgeValue_${item.label}`}>
            <StyledBubblyButton
              active={!isMobile && item.id === activeBadge?.id}
              disabled={isMobile}
              onClick={() => setActiveBadge(item)}
            >
              {item.label}
            </StyledBubblyButton>
            {isMobile && (
              <InfoContentWrap ref={animRef} animate={enterCount >= 1}>
                <Container
                  justify="center"
                  marginBottom="1rem"
                  maxWidth="700px"
                  padding="0"
                  wrap="wrap"
                >
                  <MediaComponent badge={item} />
                  <InfoContent>
                    <p>{item.description}</p>
                  </InfoContent>
                </Container>
              </InfoContentWrap>
            )}
          </React.Fragment>
        ))}
      </Container>
      {!isMobile && activeBadge && (
        <InfoContentWrap ref={animRef} animate={enterCount >= 1}>
          <Container justify="center" marginBottom="1rem" maxWidth="700px" padding="0" wrap="wrap">
            <MediaComponent badge={activeBadge} />
          </Container>
          <Container justify="center" marginBottom="0" padding="0" width="100%" wrap="wrap">
            <InfoContent>
              <p>{activeBadge.description}</p>
            </InfoContent>
          </Container>
        </InfoContentWrap>
      )}
    </StyledInfoSection>
  )
}

export default InfoSection
