import { maxWidthQueries } from '@utils/useMediaQuery'
import React, { useRef } from 'react'
import styled from 'styled-components'

// #region  style

const Mp3Player = styled.div`
  height: 110px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 99;
  flex-direction: column;
  user-select: none;
  -moz-user-select: none;
  /* animation: slideIn 1s linear;
  @keyframes slideIn {
    from {
      left: -200px;
    }
    to {
      left: 1rem;
    }
  }*/

  box-shadow: 0 0 60px 20px rgb(130, 117, 36, 0.5) inset;
  border: 2px solid #2bb3f4 rgb(82, 75, 25, 0.5);
  border-radius: 10px;
  @media ${maxWidthQueries.sm} {
    position: fixed;
    top: auto;
    left: 0;
    width: 100%;
    bottom: 80px;
    height: 50px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0;
    border: 0;
    background-size: 1000%;
    background: #2c280c;
    animation: borderAnim 1.5s infinite alternate;
    @keyframes borderAnim {
      0% {
        background-position: 0, 0;
      }
      100% {
        background-position: 100%, 45%;
      }
    }
  }
`

const StartButtonHolder = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlayerHolder = styled.div`
  min-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: absolute;
  @media ${maxWidthQueries.sm} {
    min-height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
  }
`

const PlayButton = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ theme }) => theme.cursor.default};
  &:hover {
    height: 105px;
    width: 105px;
    cursor: ${({ theme }) => theme.cursor.pointer};
  }
  @media ${maxWidthQueries.sm} {
    height: 80px;
    &:hover {
      height: 53px;
      width: 53px;
      cursor: ${({ theme }) => theme.cursor.pointer};
    }
  }
`
/*Player imag*/
const PlayButtonImg = styled.img`
  height: 100%;
  width: 100%;
  @media ${maxWidthQueries.sm} {
    height: 50px;
  }
`

const PlayerIcon = styled.img`
  height: 60px;
  position: absolute;
  @media ${maxWidthQueries.sm} {
    height: 50px;
  }
`

const HoverBloom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: #55ffff;
    height: auto;
    border-radius: 50%;
    box-shadow: 0 0 60px 10px #55ffff;
    ${PlayerIcon} {
      height: 65px;
    }
  }
  @media ${maxWidthQueries.sm} {
    /*The Music note img*/
    ${PlayerIcon} {
      height: 40px;
    }

    :hover {
      background: #55ffff;
      height: auto;
      border-radius: 50%;
      box-shadow: 0 0 45px 5px #55ffff;
      ${PlayerIcon} {
        height: 42px;
      }
    }
  }
`

const PlayerButtonHolder = styled.div`
  display: flex;
  width: 60px;
  height: 100px;
  justify-content: center;
  align-items: center;
  z-index: 0;
  @media ${maxWidthQueries.sm} {
    display: flex;
    margin-left: 50px;
    margin-right: 50px;
    justify-content: center;
    align-items: center;
  }
`

const HoverPlayerButton = styled.img`
  filter: grayscale();
  filter: blur(10px);
  height: 110px;
  position: absolute;
  z-index: -1;
`

const HoverPlayerButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  ${HoverPlayerButton} {
    opacity: 0;
  }
  :hover {
    ${HoverPlayerButton} {
      opacity: 1;
      height: 140px;
    }

    position: absolute;
  }
  @media ${maxWidthQueries.sm} {
    height: 40px;
    :hover {
      ${HoverPlayerButton} {
        opacity: 1;
        height: 45px;
      }
    }
  }
`

const VolumeSlider = styled.input`
  width: 50%;
  height: 3px;
  z-index: 1;
  top: 20px;
  position: absolute;
  appearance: none;
  -webkit-appearance: none !important;
  background: #827524;
  border: 1px solid black;
  position: absolute;
  top: 0;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background: rgb(50, 250, 250);
    border: 1px solid white;
    border-radius: 50%;
    &:hover {
      width: 15px;
      height: 15px;
      box-shadow: 0 0 60px 10px rgb(50, 250, 250);
    }
  }
  &::-moz-range-thumb {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
    background: rgb(50, 250, 250);
    border: 1px solid white;
    &:hover {
      width: 15px;
      height: 15;
      box-shadow: 0 0 60px 10px rgb(50, 250, 250);
    }
  }
  @media ${maxWidthQueries.sm} {
    top: 0;
    border-radius: 10px 0 10px 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(0.25turn, #827524, #827524);
    &::-moz-range-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: rgb(50, 250, 250);
      border: 1px solid white;
      &:hover {
        width: 25px;
        height: 25px;
        box-shadow: 0 0 60px 10px rgb(50, 250, 250);
      }
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 15px;
      height: 15px;
      background: rgb(50, 250, 250);
      border: 1px solid white;
      &:hover {
        width: 20px;
        height: 20px;
        box-shadow: 0 0 60px 10px rgb(50, 250, 250);
      }
    }
  }
`

const VolumeHolder = styled.div`
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${maxWidthQueries.sm} {
    display: none;
  }
`

const SliderBarImg = styled.img`
  height: 50px;
  top: 12px;
  width: 90%;
  position: absolute;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  @media ${maxWidthQueries.sm} {
    display: none;
  }
`

const LoopButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 10px;
  color: aliceblue;
  position: relative;
  left: -105px;
  top: 0px;
`

const LoopButton = styled.input`
  height: 20px;
  width: 20px;
  margin-top: 50px;
  margin-bottom: 15px;
  position: relative;
  appearance: none;
  border: 2px solid black;
  top: 40px;
  border-radius: 5px;
  &:hover {
    border: 2px solid white;
    border-radius: 10px;
    height: 25px;
    width: 25px;
    box-shadow: 0 0 60px 5px rgb(100, 250, 0);
  }
  &:checked {
    border: 2px solid green;
    color: #99a1a4;
    background: white;
    box-shadow: 0 0 60px 5px rgb(0, 250, 0);
  }
  @media ${maxWidthQueries.sm} {
    display: none;
  }
`

const PosChanger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

// #endregion

// #region JS
const MusicButton = () => {
  // #region  Ref
  const nextSongRef = useRef(null)
  const previousSongRef = useRef(null)

  const dragable = useRef(null)

  const loop = useRef(null)

  const currentValueRef = useRef(null)
  const sliderImgRef = useRef(null)

  // #endregion

  // region song/ song id
  const currentTrack = document.createElement('audio')
  let index = 0

  // this is the song list it is easy to add new songs
  const songList = [
    {
      name: 'Song Name',
      path: '/music/the_ether.mp3',
    },
    {
      name: 'world audio trim',
      path: '/music/world_audio_trim.mp3',
    },
    {
      name: 'Bliss Short',
      path: '/music/bliss-short.mp3',
    },
    {
      name: 'The Summoning',
      path: '/music/The_Summoning_Short_Master.mp3',
    },
  ]
  // checks if it is the first song
  let isFirstSong = true
  // endregion

  let isMoved = false

  // #region  Play the Music
  const playMusic = () => {
    if (currentTrack.paused) {
      currentTrack.play()
    } else {
      currentTrack.pause()
    }
  }

  // it checks if a song is playing else it starts the first song
  // gets the song id from the othe function and if it exist it starts the songs
  const getTrack = id => {
    if (songList[id] === null || songList[id] === undefined) {
      currentTrack.src = songList[0].path
    } else {
      currentTrack.src = songList[id].path
    }
    currentTrack.load()
    playMusic()
  }

  // checks if it is the first song and if yes it starts the first song
  const playSong = () => {
    if (!isFirstSong) {
      playMusic()
    } else {
      getTrack(0)
      isFirstSong = false
    }
  }

  // starts the next song or
  // if it is at the end of the list it restarts the list from the beginning
  const nextSong = () => {
    if (index < songList.length) {
      index++
    } else {
      index = 0
    }
    getTrack(index)
  }

  // loads the previous song
  // if its the end of the list it restarts at the beginning
  const previousSong = () => {
    if (index >= songList.length) {
      index--
    } else {
      index = songList.length
    }
    getTrack(index)
  }
  // #endregion

  // #region AutoPlay

  // starts the autoplay at the start of the page (if it does not get fixed)
  window.onload = () => {
    if (
      localStorage.getItem('autoplay') === null ||
      localStorage.getItem('autoplay') === undefined
    ) {
      localStorage.setItem('autoplay', 'true')
    } else if (localStorage.getItem('autoplay') === 'true') {
      getTrack()
    }
  }
  // #endregion

  // #region  Loop Song

  const loopTheSong = () => {
    if (loop.current.checked) {
      localStorage.setItem('looping', 'true')
      currentTrack.loop = true
    } else {
      localStorage.setItem('looping', 'false')
      currentTrack.loop = false
    }
  }

  window.onload = () => {
    if (localStorage.getItem('looping') == 'true') {
      currentTrack.loop = true
      LoopButton.checked == true
    }
  }

  // #endregion

  // #region Drag And Drop

  /*
  // if the mouse is up it stops the drag and dop
  const mouseCheck = () => {
    window.addEventListener('mouseup', q => {
      sliderImgRef.current.style.visibility = 'initial'
      isMoved = false
    })
    // Changes the position of the Player
    const MoveTo = (x, y) => {
      const drag = dragable.current
      drag.style.top = `${y}px`
      drag.style.left = `${x}px`
    }

    // this func gets the mouse Pos on the screen
    // it also gives the data to the MoveTo function
    const isMoving = (x, y) => {
      window.addEventListener('mousemove', e => {
        if (isMoved) {
          const posX = e.clientX - x
          const posY = e.clientY - y
          MoveTo(posX, posY)
        }
      })
    }

    // checks if the mouse is down and if it is it will start the pos check
    // it also makes the calculation were to grab the player
    dragable.current.addEventListener('mousedown', e => {
      isMoved = true
      if (!isMoved) return
      sliderImgRef.current.style.visibility = 'hidden'
      const tar = e.target
      const rect = tar.getBoundingClientRect()

      const x = e.clientX - rect.left
      const y = e.clientY - rect.top

      isMoving(x, y)
    })
  }

  // #endregion
*/
  // #region  Change Volume

  // it allows to change the volume of the player
  // it will also save the volume to the local storage
  // so that it can be used as save data for the next time
  const changeVolume = () => {
    isMoved = false
    const newVal = currentValueRef.current.value / 100
    currentTrack.volume = newVal
    localStorage.setItem('volume', `${newVal}`)
    const showValue = newVal * 100
  }

  // checks if the user did enter a volume if not it changes the volume to 50%
  // else it will change the volume to the saved value
  window.onload = () => {
    if (localStorage.getItem('volume') === null) {
      currentTrack.volume = 0.5
      localStorage.setItem('volume', '50')
    } else {
      const newVal = localStorage.getItem('volume')
      const showVal = newVal * 100
      currentValueRef.current.value = showVal
    }
  }

  // #endregion

  // #endregion

  // #region html

  // this is the player component
  return (
    <Mp3Player ref={dragable}>
      <VolumeHolder onMouseMove={() => changeVolume()}>
        <PosChanger>
          <VolumeSlider
            ref={currentValueRef}
            max="100"
            min="0"
            type="range"
            onMouseMove={() => changeVolume()}
          />
          {/*
          <LoopButtonHolder>
            <LoopButton ref={loop} type="checkbox" onClick={() => loopTheSong()} />
          </LoopButtonHolder>
          */}
        </PosChanger>
      </VolumeHolder>

      <PlayerHolder>
        <PlayerButtonHolder>
          <HoverPlayerButtonHolder ref={previousSongRef} onClick={() => previousSong()}>
            <HoverPlayerButton alt="hoverEffect" src="/images/musicPlayer/button-go-back.png" />
            <PlayButtonImg alt="Previous song" src="/images/musicPlayer/button-go-back.png" />
          </HoverPlayerButtonHolder>
        </PlayerButtonHolder>
        <StartButtonHolder>
          <HoverBloom>
            <PlayButton
              onClick={() => {
                playSong()
              }}
            >
              <PlayButtonImg
                alt="The ether music play/pause"
                src="/images/musicPlayer/music-player-center-button.png"
              />
              <PlayerIcon src="images/musicPlayer/play-icon.png" />
            </PlayButton>
          </HoverBloom>
        </StartButtonHolder>
        <PlayerButtonHolder>
          <HoverPlayerButtonHolder ref={nextSongRef} onClick={() => nextSong()}>
            <HoverPlayerButton alt="hoverEffect" src="/images/musicPlayer/button_skip.png" />
            <PlayButtonImg alt="Next Song" src="/images/musicPlayer/button_skip.png" />
          </HoverPlayerButtonHolder>
        </PlayerButtonHolder>
      </PlayerHolder>
    </Mp3Player>
  )
  // #endregion
}

export default MusicButton
