import ScrollInAnimation from '@components/animation/scrollIn'
import ImageButton from '@components/button/ImageButton'
import Intro from '@components/intro/Intro'
import BasicLayout from '@components/layout/BasicLayout'
import FullScreenSection from '@components/layout/FullScreenSection'
import MusicPlayerSection from '@components/musicPlayer/musicPlayerSection'
import PageSeo from '@components/seo/PageSeo'
import TwitterComponent from '@components/twitter/TwitterIntegration'
import FullScreenVideo from '@components/video/FullScreenVideo'
import getDynasties from '@operations/queries/getDynasties'
import getNews from '@operations/queries/getNews'
import getPage from '@operations/queries/getPage'
import getSiteSettings from '@operations/queries/getSiteSettings'
import getContentComponent from '@operations/utils/getContentComponent'
import useMediaQuery, { maxWidthQueries } from '@operations/utils/useMediaQuery'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  bottom: 15%;
  width: 100vw;
  height: auto;
  position: absolute;
  cursor: ${({ theme }) => theme.cursor.default};
  @media ${maxWidthQueries.sm} {
    bottom: 5%;
  }
  @media screen and (min-aspect-ratio: 21/9) and (max-aspect-ratio: 32/9) {
    bottom: 25%;
  }
`
const YoutubePopupButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 260px;
  width: 260px;
  animation: rotate 1s ease-in-out;
  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg) scale(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
  }
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
    div.buttonOverlay {
      background: rgba(34, 34, 34, 0.226);
    }
  }
  img {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  div.buttonOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s;
    display: flex;
    width: 100px;
    height: 100px;
    background: rgb(70, 70, 70);
    border: 4px solid ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    letter-spacing: 5px;
    z-index: -1;

    animation: fadeIn 1.25s ease-in;

    @keyframes fadeIn {
      from {
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        backgroundimage: none;
      }
      50% {
        opacity: 0;
        backgroundimage: none;
      }
      to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
`
const YoutubePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.4s;
  width: ${({ modalOpen }) => (modalOpen ? '100%' : 0)};
  height: ${({ modalOpen }) => (modalOpen ? '100%' : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  opacity: ${({ modalOpen }) => (modalOpen ? 1 : 0)};
  iframe {
    position: relative;
    z-index: 11;
    width: 70%;
    height: 70%;
  }
  &:hover {
    cursor: ${({ theme }) => theme.cursor.pointer};
  }
`
const PageWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
`

const Home = ({ data: { page, siteSettings, dynastiesList, news } }) => {
  const [youtubePopupOpen, setYoutubePopupOpen] = useState(false)
  const isMobile = useMediaQuery(maxWidthQueries.sm)
  const [cookiesLoaded, setCookiesLoaded] = useState(false)
  const [introOpen, setIntroOpen] = useState(true)
  const [wasIntroClosed, setWasIntroClosed] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && !cookiesLoaded) {
      setWasIntroClosed(!!window.sessionStorage.getItem('introClosed'))
      setCookiesLoaded(true)
    }
  }, [])

  const onOpenIntro = () => {
    setWasIntroClosed(true)
    window.sessionStorage.setItem('introClosed', 'true')
    setIntroOpen(false)
  }

  const togglePopup = () => {
    document
      .getElementById('youtubeModalVideo')
      .contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
    setYoutubePopupOpen(!youtubePopupOpen)
  }

  return (
    <>
      <PageSeo
        description={page.seo?.description}
        image={page.seo?.image?.formats?.medium?.url}
        schemaData={{
          '@context': 'http://schema.org',
          '@type': 'Organization',
          description: page.seo?.description,
          image: [page.seo?.image?.formats?.medium?.url],
          logo: 'https://www.skyclimbers.net/images/s-logo-128.png',
          name: page.seo?.title || `${page.title} | Skyclimbers`,
          url: 'https://www.skyclimbers.net',
        }}
        title={page.seo?.title || `${page.title} | Skyclimbers`}
      />

      <BasicLayout
        dynastiesList={dynastiesList}
        footerLinks={siteSettings?.footerLinks}
        footerLogo={siteSettings?.footerLogo?.url}
        headerLinks={siteSettings?.headerLinks}
        headerLogo={siteSettings?.logo?.url}
        hidden={!cookiesLoaded}
        socialLinks={siteSettings?.socialLinks}
      >
        <PageWrap>
          <FullScreenSection maxHeight={isMobile && 'calc(100vh - 6rem)'}>
            <img
              alt="Skyclimbers Logo"
              src="/images/s-logo-128.png"
              style={{ position: 'absolute', zIndex: '-10' }}
            />
            <FullScreenVideo
              mp4="/images/squad_gif.mp4"
              style={{ filter: 'brightness(0.9)' }}
              webm="/images/squad_gif.webm"
            />

            <YoutubePopupButton onClick={() => togglePopup()}>
              <div
                style={{
                  backgroundImage: 'url(/images/play_button.png)',
                  backgroundSize: 'cover',
                  height: '100%',
                  width: '100%',
                }}
              />
              <div className="buttonOverlay" />
            </YoutubePopupButton>
            <YoutubePopup modalOpen={youtubePopupOpen} onClick={() => togglePopup()}>
              <iframe
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className="ytVid"
                frameBorder="0"
                id="youtubeModalVideo"
                src="https://www.youtube.com/embed/A6RkaM7GvGw"
                title="YouTube video player"
              />
            </YoutubePopup>
            <ButtonWrapper>
              <ScrollInAnimation delay="1">
                <ImageButton
                  alt="Playstation Store"
                  href="https://store.playstation.com/en-us/concept/10007232"
                  image={isMobile ? '/images/ps_mobile.png' : '/images/playstation-home-button.png'}
                  margin="10px"
                  target="_blank"
                />
              </ScrollInAnimation>
              <ScrollInAnimation delay="2">
                <ImageButton
                  alt="skyclimbers steam button"
                  href="https://store.steampowered.com/app/1362000/Skyclimbers/"
                  image={isMobile ? '/images/steam_mobile.png' : '/images/steam_button.png'}
                  margin="10px"
                  target="_blank"
                />
              </ScrollInAnimation>
              <ScrollInAnimation delay="3">
                <ImageButton
                  alt="our etsy store button"
                  href="https://www.etsy.com/shop/Skyclimbers"
                  image={isMobile ? '/images/shop_mobile.png' : '/images/shop_desktop.png'}
                  margin="10px"
                  target="_blank"
                />
              </ScrollInAnimation>
            </ButtonWrapper>
          </FullScreenSection>
          {page.content?.map(item => getContentComponent(item))}
          <TwitterComponent news={news} />
        </PageWrap>

        <MusicPlayerSection />
      </BasicLayout>
      {!wasIntroClosed && introOpen && !isMobile && cookiesLoaded && (
        <Intro onClick={() => onOpenIntro()} />
      )}
    </>
  )
}

export const getStaticProps = async () => {
  let page
  let dynastiesList = []
  let siteSettings
  let news = []
  if (process.env.API_URL) {
    page = await getPage('home')
    siteSettings = await getSiteSettings()
    dynastiesList = await getDynasties()
    news = await getNews()
  }

  return {
    props: {
      data: {
        dynastiesList: dynastiesList || null,
        news: news || null,
        page: page?.[0] || null,
        siteSettings: siteSettings || null,
      },
    },
    revalidate: false,
  }
}

export default Home
