import React from 'react'
import styled, { useTheme } from 'styled-components'
import MusicButton from '@components/musicPlayer/MusicPlayer'

const PlayerSectionWrapper = styled.div`
  width: auto;
  height: auto;
  background: rgb(44, 40, 13);
`

const MusicPlayerSection = () => {
  return (
    <PlayerSectionWrapper>
      <MusicButton />
    </PlayerSectionWrapper>
  )
}
export default MusicPlayerSection
