import React, { useRef } from 'react'
import styled from 'styled-components'

const ScrollInAnimationHolder = styled.div`
  position: relative;
  height: ${({ height }) => height || '100px'};
  display: flex;
  visibility: hidden;
  &.animate {
    visibility: visible;
    animation: sc 0.7s ease-in-out;
  }

  @keyframes sc {
    0% {
      transform: translate(0, 100px) scale(0);
    }
    100% {
      transform: translate(0, 0) scale(1);
    }
  }
`

const ScrollInAnimation = ({ children, delay, height }) => {
  const scrollRef = useRef(null)

  setTimeout(() => {
    scrollRef?.current?.classList.add('animate')
  }, delay * 1000)

  return (
    <ScrollInAnimationHolder ref={scrollRef} height={height}>
      {children}
    </ScrollInAnimationHolder>
  )
}

export default ScrollInAnimation
