import BlackButton from '@components/button/BlackButton'
import News from '@components/news/news'
import GoldFullWidthTitle from '@components/title/GoldFullWidthTitle'
import { maxWidthQueries } from '@operations/utils/useMediaQuery'
import React from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-image: ${({ theme }) => `url(${theme.images.whitePattern})`};
  background-size: 30%;
  overflow: hidden;
`
const TwitterWrapper = styled.div`
  min-height: 500px;
  min-width: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 4rem;
  @media ${maxWidthQueries.sm} {
    padding: 2rem;
    flex-direction: column;
  }
`
const ButtonDist = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px;
  min-height: 500px;
  width: 280px;
  border: 2px solid black;
  padding-top: 10px;

  @media ${maxWidthQueries.sm} {
    margin: 20px;
  }
  :hover {
    border: 2px solid ${({ theme }) => theme.colors.teal};
  }
`

const CreatorKitButton = styled.img`
  height: 420px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  object-fit: contain;
  background: white;
  &:hover {
    filter: brightness(75%);
  }
`
const TwitterTimelineWrapper = styled.div`
  height: 512px;
  overflow-y: scroll;
  overflow-x: hidden;
`
const ButtonWrapper = styled.div`
  min-height: 30px;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 100%;
  display: flex;
`
const TwitterComponent = ({ news }) => {
  const Click = () => {}
  console.log(news)
  return (
    <>
      <Wrapper>
        <GoldFullWidthTitle centerTick={false} title="News" />
        <TwitterWrapper>
          <ButtonDist>
            <a
              href="https://drive.google.com/drive/folders/1p1gAxJn4E2S4AtDNLEngpwtS-4qFjTvF"
              rel="noreferrer"
              target="_blank"
            >
              <CreatorKitButton alt="background img" src="images/pressKit/creator-kit-icon.png" />
            </a>
            <ButtonWrapper>
              <a
                href="https://drive.google.com/drive/folders/1p1gAxJn4E2S4AtDNLEngpwtS-4qFjTvF"
                rel="noreferrer"
                target="_blank"
              >
                <BlackButton width="250px" onClick={Click}>
                  Creator Kit
                </BlackButton>
              </a>
            </ButtonWrapper>
          </ButtonDist>

          <News news={news} />

          <ButtonDist>
            <a
              href="https://drive.google.com/drive/folders/1jKo3JBDS7qKZAu6j0LyC5FC5uJCRwTJg"
              rel="noreferrer"
              target="_blank"
            >
              <CreatorKitButton alt="background img" src="images/pressKit/press-kit.png" />
            </a>
            <ButtonWrapper>
              <a
                href="https://drive.google.com/drive/folders/1jKo3JBDS7qKZAu6j0LyC5FC5uJCRwTJg"
                rel="noreferrer"
                target="_blank"
              >
                <BlackButton width="250px" onClick={Click}>
                  Press Kit
                </BlackButton>
              </a>
            </ButtonWrapper>
          </ButtonDist>
        </TwitterWrapper>
      </Wrapper>
    </>
  )
}

export default TwitterComponent
