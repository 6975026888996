import React from 'react'

const WhiteTextLogo = () => {
  return (
    <svg
      height="45"
      version="1.2"
      viewBox="0 0 1238 65"
      width="1238"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>logo-centered-SC-svg</title>
      <defs>
        <image
          height="40"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAoCAMAAABU4iNhAAAAAXNSR0IB2cksfwAAAHJQTFRF////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////ol40LQAAACZ0Uk5TID9ff5+//99PMAAQQIDg769QkHCgY3gPAKgYsMAIw48D0M/wYG+h+btfAAABTUlEQVR4nJXU63aDIAwAYJiK5TbrZbVr7XrRvf8rTo8QgkDb5RcnfieKhBBC6Ue2RF6YYEuURZ7tuEBBhJBK6U8WjWpfY7lEE5dzqI1sk7KSvlRJyTpfEnjwpdY4bN9vpIB8fzTxbTPal7D5k5XSZs6+HGyeHrdFh5eSvi37uGzelvr/kgdSJ2QfyDYhL8HeSUKu6nKih5810Yi4vN56eoCDdCcUyrvSHXJQMZSPeX23bOyISEo2ryfD3M1ISTkui2K+cxmVacmh6IvvXDt8cJQ8l2QE2T2XonZFVUqaU3bXukhJ0znoUydfnreSgyylJ6EE/BY3gPZxOVgp3f7zqHzAr0azaopJBlKgUZnHpGsfdKhz1VBOruiIKA9l56RGsjWyriBVOcmRbFZ5RSmGGhi9fiCE/mYlhqzYQfui7iuJWAZwq01M60C2UmkXf6CFP8GDBq5kAAAAAElFTkSuQmCC"
          id="img1"
          width="41"
        />
        <image
          height="41"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAApCAMAAACInGANAAAAAXNSR0IB2cksfwAAAGBQTFRF////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////VMjlmgAAACB0Uk5TEDA/AGDw/8+/j39fQJ+ggFUBABpQsAcg4O+QwB9PcN9ec1NwAAAA70lEQVR4nL3Uf3ODIAwGYEosUsSilP5at+77f8vNQaJnTOv1env/4cg9kuhxKrXRGh5Hgaq2xpjajtm5kgYNgDdSWjKwF1Ego7YiqtBAmFS7vo/xMEXZjAfFkvGZVAwc52ZEAU14ycT/NN0Kc3rzPGmFOS+ZQ16pFTPG2WzLyBfFzNXHmAaFb5WAGfs3yubjWvY34IZmTnXeVwvmE1FTmp+50WhUKdQPTLQGu8nG0UGy+aIPPTeOjMaSX2HuK0yQTSOalkwrGkvmWzTGOj1kR2TB8KTnht95lj2w/8YCQXMTGz0zRw8TU4VZ7t5n8JsfAsotPwP4SRIAAAAASUVORK5CYII="
          id="img2"
          width="35"
        />
      </defs>
      <path
        d="m35.6 0.4c-0.6 5.3 0 10.5-0.1 15.8q-3.3-1.6-6.5-3.5c-4 0.9-8.8-0.1-12.3 2-2.2 1.9-2.5 5.8-1.9 8.4 1.2 3.3 4.4 4.5 7.2 6 3 1.3 5.7 3.2 8.5 4.9v1c3 3.1 7.5 7.3 7.1 12-0.4 3.7-1.5 8.2-5.4 9.7-4.6 2.2-9.8 3.5-14.8 4.5-3.9 0.5-7.9 0.2-11.8 0.2-0.5-6.4-2.8-11.9-5.3-17.8 2.4 4.3 6.8 5.4 9.6 9q1.9 0 3.9-0.1c3-3 6.9-1.1 11-3 0-2.2 0.2-4.5-0.3-6.6-3.4-3.2-8.4-4.4-12-7.5-2.8-2.3-6.5-4.4-8.2-7.7-1.5-2.4-0.9-6-0.6-8.6 0.3-5.1 4.4-9.4 8.7-11.7 5.7-2.8 11.6-1.6 17.8-1.5l0.6-0.7q2.4-2.4 4.8-4.8z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <path
        d="m404.2 17.8c0.2 5.8 0.9 11.2-1.2 16.7q-0.9-3.4-1.7-6.8-4.3-0.2-8.5-0.2c-3.6 3.1-7.9 6.4-9.4 11.1-2.1 4.7 0.8 10.5 4.2 14 2.5 2.2 5.1 1.8 8.2 2.2 1.8 0.5 3.2 1.5 5.1 0.6 1.4-0.7 1.7-3 2.4-4.4 0.1 4.5 0.1 8.9-0.1 13.4q-0.7-0.1-1.4-0.1-0.3-1.4-0.7-2.7c-3.7-0.4-7.4-0.2-11.2-0.2-4.8-1.8-9.2-3.9-12.9-7.5-2.1-2.1-3-5.2-4.1-7.9-0.5-2.7-0.5-5.3-0.2-8 0.7-2 1.4-4 2.1-6 1.8-1.6 3.4-3.4 5.3-4.9 3.1-2.4 6.9-3.8 10.8-4.3 3.4-0.6 7 0.5 10.5 1q1-1.2 1.9-2.5 0.5-1.7 0.9-3.5z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <path
        d="m755.5 17.3c0.1 2.8-0.6 6 0.2 8.6 1.8 3.7 3.6 7.4 5.1 11.2 1.5 4.5 3.6 8.7 5.1 13.1 1.6 3.6 3.5 7 5.9 10.1q2.4 0.3 4.8 0.7c-2.5 0.4-4.9 0.8-7.3-0.3q-4.4 0-8.8 0c0.4-3.8-1.6-6.5-2.6-9.9-1.5-5-3.5-9.9-5-14.9-1.9 5.2-3.1 10.1-6 14.9-1.5 2.3-3 4.6-4.7 6.8-0.8 1.2-2.8 0.8-4.1 1-1.9-2.4-4.1-4.3-5.3-7.2-2-4.4-3.9-8.8-6-13.1-2.1 3.1-2.5 6.9-4.3 10.2-1.6 4.2-4.3 8.4-3.8 13-3.7 0-7.5 0-11.2-0.2 2.3-1.8 4.7-2.6 5.7-5.5 2.8-6.9 5.2-14 7.8-21q1.1-0.3 2.1-0.6c0.3-5.5 4.1-9.3 3.8-15q0.6 1.8 1.3 3.7c1.2 2 2.4 3.8 2.9 6.2 0.9 5.8 4.3 10.1 6.1 15.8 0.9 2.2 1.9 4.3 2.8 6.6q0.6-0.1 1.2-0.1 0.3-1.2 0.6-2.4c4.9-9.2 8.1-19.5 12.3-29q0.7-1.4 1.4-2.7z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <path
        d="m120.3 21.7c6.1-0.7 12.3-0.5 18.4-0.1q-1.1 0.6-2.2 1.2c-1.5 4.9-1.1 9.7-0.9 14.7 3.5-3.2 6.1-7.1 9.3-10.5 1.6-1.6 2.8-3.5 3.9-5.4 3.8 0.6 7.5 0.8 11.4 0.9-3.1 1-5 2.2-7.2 4.5-3.6 3.6-7.2 7.1-10.6 10.9q0.7 1.5 1.3 3.1c2 3.1 4.2 6 6.5 8.9 1.8 2.3 3 4.9 5.5 6.5 2.6 1.7 5.3 3.3 8 4.9-5.3 0.2-10.5 0.2-15.8 0.1-4.1-5.8-9.1-10.5-12.4-16.9-0.9 5.7 1.1 10.6 0.9 16.2-5.2-0.2-11 0.7-16.1-0.8 2-0.2 4.1-0.3 6.1-0.4q0.1-2.9 0.1-5.8 0.7-0.8 1.3-1.7-0.6-0.7-1.2-1.4c0.2-9.1-0.1-18.1 0-27.2-2.1-0.5-4.2-1-6.3-1.7z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <path
        d="m491.9 21.9c4.8-0.1 9.5 0 14.3 0q0.2 0.6 0.4 1.3-1.6 0.1-3.3 0.1c-2.3 8.5 0.1 18.1-1.3 26.8-0.4 2.2-0.4 4.4-0.5 6.7 3.5 0 7.2 0.2 10.6-0.5 4.3-1.4 8.1-3.4 9.3-8.2 0.1 4.3 0 8.5-0.7 12.7-4.1 1.1-8.3 0.6-12.7 0.6-7.7-0.1-15 0.6-22.6-1.1 2.8-1.4 5-3.2 8.2-3.1-0.5-11.1 0.9-22.6-1.2-33.4q-1.8-0.4-3.5-0.8c1-0.9 1.7-1.1 3-1.1z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <use href="#img1" id="Layer" x="1080" y="22" />
      <path
        d="m249.5 22.8c4.7-0.7 9.4-0.5 14-0.6-1.3 6 3.5 11.2 5.6 16.7 3.3-4.8 6.2-10.8 6.3-16.7 4.1 0.1 8.2 0.1 12.3 0.3-5.6 0.6-6.5 4.1-8.5 8.2-1.8 4.3-5.1 6.5-5.6 11.3-0.6 5.3-0.4 10.7-0.1 16q2.5 0.6 4.1 2.4c-4.8 0.2-9.7 0.1-14.5 0.7q-1.1-1-2.2-1.8c-0.5 0.8-0.9 1.7-1.3 2.6l-0.2-3c2.1-0.3 4-0.7 6-1.5 0.4-4.1 0.2-8.2 0.2-12.4-0.2-3.5-2.4-5.9-4.6-8.4-1.7-4.4-3.6-8.8-5.8-13q-2.9-0.3-5.7-0.8z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <path
        d="m607.2 22.6c6.6-0.4 13.2-0.6 19.8 0.2q-2.6 0.4-5.2 0.8c-1.4 6.8-1.9 13.5-0.7 20.4 0.1 5.5 0.4 10.9 1.6 16.3q0.9 0.5 1.8 0.9c-6.4 0.3-12.7 0.3-19.1 0.2 2.1-1.2 4.4-2.3 5.6-4.5 1.4-3.7 1.4-8.1 1.3-12-1.2-2.6-0.9-5.1-0.9-7.9 0-4.2 0-8.4 0.1-12.6q-2.1-1-4.3-1.8z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <path
        d="m1222.6 22.8c4.7-1 9.4-0.6 13.8 1q0 3.2-0.2 6.3-0.5 0.2-1 0.5c-0.7-1.5-1.2-3.1-3.1-3.2-3.5-0.4-6.3 0-9.6 1.5q-0.2 3-0.2 6.1c4.4 4.5 9.9 5.9 15.1 8.9 0.2 2.9 0.7 6.3-0.1 9.1-1.4 2.7-3.7 5.1-6.5 6.3-4.5 2.2-10.5 1.2-15.5 1.4 0.6-3.4-0.5-6.1-1.5-9.3 1.7 1 3.4 2 5.1 3 3.6 0.1 7-0.6 10.4-1.7q0.7-1.5 1.3-3c-1.1-1.3-2.2-2.5-3.8-3.2-3.8-1.8-7.3-4.2-10.2-7.1-1.8-1.8-1.4-5.1-1.5-7.4-0.4-4.3 3.7-8.2 7.5-9.2z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <path
        d="m972.2 22.4c8.7-0.3 17.4-0.1 26-0.1q0.2 2.3 0.4 4.5c-4.7-0.4-9.4-0.3-14.1 0.1-0.4 3.3-1.1 6.4-0.1 9.7 3.5 0 6.8 0.2 10.1-1.1q-0.2 0.7-0.4 1.5c-0.8 2.8-0.4 4.6 0.7 7.3-3.5-1.3-6.7-0.9-10.3-0.9-0.8 4.1-1 8-0.3 12.2 4.8 0.7 9.3 1 14-0.3q0.5-2.1 1-4.3c0.2 3.5 0.2 6.9 0.2 10.4-8.2 0.2-16.3 0-24.5 0.1 2.9-5.3 1-11.5 1.9-17.5 0.4-2.1 0.6-4 0.1-6.1-0.8-4.7 0.3-9.5-0.7-14.2z"
        id="Layer"
        style={{ fill: '#fff' }}
      />
      <use href="#img2" id="Layer" x="854" y="21" />
    </svg>
  )
}

export default WhiteTextLogo
