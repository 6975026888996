import GoldFullWidthTitle from '@components/title/GoldFullWidthTitle'
import { minWidthQueries } from '@operations/utils/useMediaQuery'
import React, { useRef } from 'react'
import { useInViewport } from 'react-in-viewport'
import styled from 'styled-components'

const LogoWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${({ theme }) => `url(${theme.images.whitePattern})`};
  background-size: 30%;
  flex-direction: column;
  overflow: hidden;
`
const Img = styled.img`
  height: auto;
  margin: 10px;
  width: 170px;
  @media ${minWidthQueries.md} {
    width: 200px;
  }
`
const LogoFlex = styled.div`
  transform: translateY(${({ animate }) => (animate ? '0' : '300px')});
  transition: transform 1.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 2rem 0;
  @media ${minWidthQueries.md} {
    padding: 5rem 0;
    flex-direction: row;
    max-width: 740px;
  }
`

const LogosSection = ({ logos, title }) => {
  const animRef = useRef()
  const { enterCount } = useInViewport(animRef)

  return (
    <LogoWrapper ref={animRef}>
      {title && <GoldFullWidthTitle centerTick={false} title={title} />}
      <LogoFlex animate={enterCount >= 1}>
        {logos?.map(logo => {
          const logoUrl =
            logo.image?.ext === '.svg' ? logo.image?.url : logo.image.formats.medium.url
          if (!logoUrl) return null
          return (
            <a key={logo.id} href={logo.url} rel="noreferrer" target="_blank">
              <Img alt={logo.name} src={logoUrl} />
            </a>
          )
        })}
      </LogoFlex>
    </LogoWrapper>
  )
}
export default LogosSection
